import React from "react"
import { Seo } from "layout"
import {
  BottomHeroSection,
  FaqSection,
  HeroSection,
  MidSection,
  StatsSection,
} from "sections"
import { GiSlashedShield } from "@react-icons/all-files/gi/GiSlashedShield"
import { GiAlarmClock } from "@react-icons/all-files/gi/GiAlarmClock"
import { GiMoneyStack } from "@react-icons/all-files/gi/GiMoneyStack"
import { BiNetworkChart } from "@react-icons/all-files/bi/BiNetworkChart"
import { FaLeaf } from "@react-icons/all-files/fa/FaLeaf"
import { FaWheelchair } from "@react-icons/all-files/fa/FaWheelchair"
import { FaWifi } from "@react-icons/all-files/fa/FaWifi"
import { FaMobileAlt } from "@react-icons/all-files/fa/FaMobileAlt"
import  { FaHeadset } from "@react-icons/all-files/fa/FaHeadset"
import { ReviewsSection } from "sections/home/reviews"

const sections = {
  features: {
    title: "A Better Way To Travel",
    description:
      "Experience travel like never before with BusLinker. We combine comfort, convenience, and affordability to offer you a superior travel experience. Our modern buses, exceptional service, and extensive route network ensure that your journey is smooth, enjoyable, and stress-free.",
    featuresTitle: "Features",
    imageUrl: "https://images.unsplash.com/photo-1557223563-8db8e5e7d90b?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    imageAlt: "Bus",
    direction: "left",
    features: [
      {
        title: "Safety",
        description:
          "At BusLinker, your safety is our top priority. We adhere to the highest safety standards, regularly maintaining and inspecting our fleet to ensure each journey is secure and worry-free.",
        icon: GiSlashedShield,
      },
      {
        title: "Reliability",
        description:
          "BusLinker prides itself on being a reliable choice for your travel needs. We are dedicated to punctuality, with a comprehensive schedule that ensures timely departures and arrivals. You can count on us to get you to your destination on time, every time.",
        icon: GiAlarmClock,
      },
      {
        title: "Affordable",
        description:
          "We believe that quality transportation should be accessible to everyone. BusLinker offers competitive pricing without compromising on comfort or service. Enjoy affordable travel options that fit your budget, making it easier to explore and connect across Pakistan.",
        icon: GiMoneyStack,
      },
      {
        title: "Largest Network in the Pakistan",
        description:
          "With the largest network of routes across Pakistan, BusLinker ensures that you can reach even the most remote destinations with ease. Our extensive coverage connects major cities and towns, providing you with unparalleled access to the entire country.",
        icon: BiNetworkChart,
      },
      {
        title: "Environmentally Friendly",
        description:
          "BusLinker is committed to reducing our environmental footprint. Our modern fleet is designed to be energy-efficient, and we continuously seek ways to implement greener practice.",
        icon: FaLeaf,
      },
    ],
  },
  features2: {
    title: "Travel Redefined",
    description:
      "Discover the next level of travel with BusLinker. Our commitment to innovation, efficiency, and customer satisfaction guarantees a superior travel experience. With state-of-the-art amenities and exceptional service, your journey is set to be memorable.",
    featuresTitle: "Benefits",
    imageUrl: "https://images.unsplash.com/photo-1619240538722-3e27d5cf6e63?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    imageAlt: "Bus",
    features: [
      {
        title: "Premium Comfort",
        description:
          "Travel in utmost comfort with our plush seating, ample legroom, and climate control systems. Whether a short trip or a long journey, enjoy a relaxing ride with BusLinker.",
        icon: GiSlashedShield,
      },
      {
        title: "Accessibility Features",
        description:
          "Our buses are equipped with features to accommodate passengers with disabilities, ensuring a comfortable and accessible journey for everyone.",
        icon: FaWheelchair,
      },
      {
        title: "Seamless Connectivity",
        description:
          "Stay connected with free Wi-Fi and multiple charging ports on all our buses. Work, stream, or browse without interruption.",
        icon: FaWifi,
      },
      {
        title: "Efficient Booking Process",
        description:
          "Book your tickets effortlessly with our user-friendly website and mobile app. Enjoy quick and easy booking with multiple payment options.",
        icon: FaMobileAlt,
      },
      {
        title: "Dedicated Support",
        description:
          "Our 24/7 customer support team is always ready to assist you with any inquiries or issues, ensuring a hassle-free experience.",
        icon: FaHeadset,
      },
    ],
  },
}

const IndexPage = () => (
  <>
    <Seo title="Explore & Reserve Bus Tickets" />
    <HeroSection />
    <MidSection section={sections.features} />
    <MidSection section={sections.features2} />
    <StatsSection />
    <ReviewsSection />
    <FaqSection />
    <BottomHeroSection />
  </>
)

export default IndexPage
